<template>
  <div class="main-app">
    <monit-bar />
    <router-view />
  </div>
</template>

<script>
import MonitBar from "@/MonitBar";

export default {
  name: "App",
  components: {
    MonitBar,
  },
};
</script>
