import Vue from "vue";
import Vuex from "vuex";

import router from "@/router";

import { clearAuthToken } from "@/utils/authToken";

import viewport from "./viewport";
import orders from "./orders";
import reloads from "./reloads";
import user from "./user";
import sharing from "./sharing";
import nof from "./nof";
import countries from "./countries";
import aml from "./aml";
import mfa from "./mfa";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    viewport,
    orders,
    reloads,
    user,
    sharing,
    nof,
    countries,
    aml,
    mfa,
  },
  state: {
    isProduction: !!document.querySelector("[data-is-production='True']"),
    googleAnalyticsId: document
      .querySelector("[data-ga-id]")
      .getAttribute("data-ga-id"),
    isGoogleAnalyticsEnabled: false,
    recaptchaSiteKey: document
      .querySelector("[data-recaptcha-site-key]")
      .getAttribute("data-recaptcha-site-key"),
    monitText: "",
  },
  actions: {
    async initialize({ dispatch }) {
      await dispatch("user/initializeUserData");
      await dispatch("orders/initializeOrdersData");
      console.log("Store initialization successful");
    },
    logout({ dispatch }) {
      dispatch("resetState");
      clearAuthToken();
    },
    resetState({ commit }) {
      commit("user/resetUserState");
      commit("orders/resetOrdersState");
      commit("reloads/resetReloadsState");
      commit("sharing/resetSharingState");
      commit("nof/resetNofState");
      commit("mfa/resetMfaState");
    },
    enableGoogleAnalytics({
      commit,
      state: { googleAnalyticsId, isProduction, isGoogleAnalyticsEnabled },
    }) {
      if (isGoogleAnalyticsEnabled) {
        return;
      }

      const VueAnalytics = require("vue-analytics").default;

      Vue.use(VueAnalytics, {
        id: googleAnalyticsId,
        router,
        debug: {
          sendHitTask: isProduction,
        },
      });
      commit("setGoogleAnalyticsEnabledState", true);
    },
    disableGoogleAnalytics({ commit, state: { isGoogleAnalyticsEnabled } }) {
      if (!isGoogleAnalyticsEnabled) {
        return;
      }
      Vue.$ga.disable();
      commit("setGoogleAnalyticsEnabledState", false);
    },
  },
  mutations: {
    setGoogleAnalyticsEnabledState(state, isEnabled) {
      state.isGoogleAnalyticsEnabled = isEnabled;
    },
    setMonitText(state, text) {
      state.monitText = text;
    },
  },
});

export default store;
