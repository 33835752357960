<template>
  <div v-if="monitText" class="monit-bar">
    {{ monitText }}
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchFromApi } from "@/utils/api";

export default {
  name: "MonitBar",

  computed: {
    ...mapState({
      monitText: (state) => state.monitText,
    }),
  },

  async created() {
    if (!this.monitText) {
      try {
        const response = await fetchFromApi("/announcements/");
        // it returns a list of monit texts, but for simplicity we display only one
        if (!response) {
          return;
        }
        this.$store.commit("setMonitText", response[0].text);
      } catch (error) {
        console.error("Failed to fetch monit text:", error);
      }
    }
  },
};
</script>

<style scoped>
.monit-bar {
  padding: 8px;
  background: #f8d7da;
  color: #721c24;
  text-align: center;
  z-index: 1000;
  font-size: 14px;
}
</style>
